import $axios from '../../plugins/axios'
import jwt_decode from 'jwt-decode';

export default {
    state: {
        authToken: localStorage.getItem('authToken') || '',
        user: null,
        livreur: null,
        nip : sessionStorage.getItem('nip'),
        nipLivreur : sessionStorage.getItem('nipLivreur'),
    },
    getters: {
        isLoggedIn: state => !!state.authToken,
        decoded: state => state.authToken ? jwt_decode(state.authToken) : '',
        userLoggedOut: state => state.nip ? false : true,
        livreurLoggedOut: state => state.nipLivreur ? false : true,
        user: state => state.user,
        nip: state=> state.nip,
        nipLivreur: state=> state.nipLivreur,
        livreur: state => state.livreur,
        isIdle : state => state.nip ? true : false,
    },
    actions: {
        async login({ commit }, user) {
            let resp = await $axios.get(process.env.VUE_APP_API_URL + '/auth?id=' + user.succursale + '&password=' + user.password)  
            commit("login", resp.data.token);
        },
        async logout({ commit }) {
            commit("logout");
        },
        livreurLogin({commit},livreur){
            commit("livreurLogin",livreur)
        },
        userLogin({commit, getters}, nip){
            commit("userLogin", Object.values(getters.db.employe).find(element => element.nip == nip))
        },
        idleInterface({ commit }){
            sessionStorage.removeItem('nip');
            commit('userLogout');
        }
    },
    mutations: {
        logout(state) {
            state.authToken = null
            localStorage.removeItem('authToken')
        },
        login(state, authToken){
            state.authToken = authToken
            localStorage.setItem('authToken', authToken)
            // $axios.defaults.headers.jwt = authToken;
        },
        userLogin(state, user){
            state.user = user
            sessionStorage.setItem('nip',user.nip)
            state.nip = user.nip
        },
        userLogout(state){
            sessionStorage.removeItem('nip');
            state.user = null
            state.nip = null
        },
        livreurLogin(state,livreur){
            sessionStorage.setItem('nipLivreur',livreur.nip)
            state.nipLivreur = livreur.nip
            state.livreur = livreur
        },
        livreurLogout(state){
            sessionStorage.removeItem('nipLivreur');
            state.nipLivreur = null
            state.livreur = null
        },
    }
}
