<template>
    <v-autocomplete
      autofocus
      :auto-select-first="true"
      :no-data-text="'AUCUN RÉSULTAT: Essayer de rechercher par NOM, PRÉNOM, APPARTEMENT, DATE DE NAISSANCE'"
      :items="items"
      :search-input.sync="search"
      item-text="fullname"
      v-model="searchPatientId"
      item-value="id"
      outlined
      solo
      dense
      hide-details
      no-filter
      :label="this.label"
      ref="recherche"
      @change="onSelectionChange"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.prenom }} {{ data.item.nom }}
            <span v-if="data.item.appt" class="green white--text">&nbsp;{{ data.item.appt }}&nbsp;</span>
            {{ data.item.ddn | toDate }}
            <span v-if="data.item.foyer_id" class="orange white--text">&nbsp;{{ db.foyer[data.item.foyer_id].nom_reflex }}&nbsp;</span>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    props: {
      patientsArray: Array,
      searchHistory: Array,
      label: String
    },
    data() {
      return {
        searchPatientId: '',
        search: null,
        items: []
      };
    },
    computed: {
      ...mapGetters(['db'])
    },
    watch: {
      search(val) {
        if (val && val !== this.searchPatientId) {
          this.querySelections(val);
        }
      },
      searchHistory(newValue) {
        if (typeof newValue !== 'undefined' && newValue.length > 0) {
          this.items = newValue;
        }
      }
    },
    methods: {
      querySelections(queryText) {
        this.items = this.patientsArray.filter(item => {
          let termesRecherches = queryText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().split(' ');
          return termesRecherches.every(terme => item.searchTerm.includes(terme));
        });
      },
      onSelectionChange(value) {
        this.$emit('patient-selected', value);
      },
      focusRecherche() {
        this.$refs.recherche.focus();
      },
      clear() {
        this.search = null;
        this.searchPatientId = '';
        this.$refs.recherche.blur();
      }
    },
    mounted() {
      this.items = this.searchHistory;
    }
  };
  </script>
  