<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody"
      ></v-skeleton-loader>
      <template v-else>
        <template v-if="registre_changement?.registre!=null">
          <div v-for="(categorie, pilulier) in registre_changement?.registre" :key="pilulier">
            <h4>{{ pilulier }}</h4>
            <table class="table-registre">
              <thead>
                <tr class="header">
                  <th>#/7jr</th>
                  <th class="text-left">Médicament</th>
                  <th class="text-left">Teneur</th>
                  <th>Presc.</th>
                  <td v-for="(h,horaireName) in abbrvHoraire" :key="horaireName" >{{ h }}</td>
                </tr>
              </thead>
              <template v-for="(groupEquivalent,groupKey) in categorie">
                <tbody v-for="(item,index) in groupEquivalent" :key="item.nom+index"  :set="posologie_hebdo = item.posologie_type === 'hebdomadaire' ? formatPosologieHebdo(item.posologie_json) : null">
                  <tr class="bold-line">
                    <td :class="'qte-duree-column ' +getClass(hasChanged(`${pilulier}.${groupKey}[${index}].qte_duree`))">{{ formatDecimal(item.qte_duree) }}</td>
                    <td :class="'medicament-column ' +getClassMedicament(`${pilulier}.${groupKey}[${index}].nom`,item.nom)" >{{ item.nom }}</td>
                    <td :class="'teneur-column ' +getClass(hasChanged(`${pilulier}.${groupKey}[${index}].teneur`))" >{{ item.teneur }}</td>
                    <td :class="'prescripteur-column ' +getClass(hasChanged(`${pilulier}.${groupKey}[${index}].prescripteur_nom`))" >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on"> {{ formatPrescripteur(item.prescripteur_nom) }} </span>
                        </template>
                        <span>{{ item.prescripteur_nom }} {{ item.prescripteur_licence}}</span>
                      </v-tooltip>
                    </td>
                    <template v-if="item.posologie_type === 'hebdomadaire'">
                      <td :class="'horaire-column '" v-for="(seq,horaireName) in posologie_hebdo" :key="horaireName" >{{ seq }}</td>
                    </template>
                    <template v-else>
                      <td :class="'horaire-column '+getClass(hasChanged(`${pilulier}.${groupKey}[${index}].posologie_json.sequence.${h}`))" v-for="h in horaires" :key="h">{{ item.posologie_json.sequence[h] || '' }}</td>
                    </template>
                  </tr>
                  <tr v-if="item.posologie_type === 'hebdomadaire' || ((item.debut_datetime_swt!=null && item.debut_datetime_swt!='' && item.debut_datetime_swt!='1753-01-01') || (item.fin_datetime_swt!=null&& item.fin_datetime_swt!=''))">
                    <td colspan="8">
                      <p v-if="item.posologie_type === 'hebdomadaire'" :class="getClass(hasChanged(`${pilulier}.${groupKey}[${index}].posologie_text`))">{{ item.posologie_text }}</p>
                      <template v-if="(item.debut_datetime_swt!=null && item.debut_datetime_swt!='' && item.debut_datetime_swt!='1753-01-01') || (item.fin_datetime_swt!=null&& item.fin_datetime_swt!='')" >
                        <p :class="getClass(hasChanged(`${pilulier}.${groupKey}[${index}].debut_datetime_swt`))+ +getClass(hasChanged(`${pilulier}.${groupKey}[${index}].fin_datetime_swt`))">
                          {{ (item.debut_datetime_swt!=null && item.debut_datetime_swt!='' && item.debut_datetime_swt!='1753-01-01') ? 'Début: ' : '' }}{{ item.debut_datetime_swt | toDate('-') }} {{ (item.fin_datetime_swt!=null&& item.fin_datetime_swt!='') ? 'Fin: ' : '' }} {{ item.fin_datetime_swt | toDate('-') }}
                        </p>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </table>
            <br />
          </div>
        </template>
        <v-alert v-else type="warning">La version {{ version }} est vide</v-alert>
        <v-dialog v-model="dialog" persistent max-width="400" v-if="registre_changement?.registre && type == 'droite' && user.type=='pharmacien' && user.licence!=''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-1" small color="primary" dark v-bind="attrs" v-on="on" dense>
              Signer registre
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Confirmation
            </v-card-title>
            <v-card-text>Voulez-vous signer le registre de médicaments?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error"  @click="dialog = false">
                Non
              </v-btn>
              <v-btn color="success"  v-on:click="signerRegistre()">
                Oui
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </template>
    </v-card>
  </v-container>
</template>
<script>
import {hasProperty , getProperty} from 'dot-prop';
import { mapGetters } from "vuex";

export default {
  props: ["patient_id", "registre_changement","type","show_diff","loading","version"],
  data() {
    return {
      expanded: [],
      headers: [
        { text: 'Qte/Durée', value: 'qte_duree', sortable: false },
        { text: 'Nom', value: 'nom' },
        { text: 'Teneur', value: 'teneur' },
        { text: 'Debut', value: 'debut' },
        { text: 'Fin', value: 'fin' },
        { text: 'Prescripteur', value: 'prescripteur' },
        { text: '', value: 'data-table-expand' },
      ],
      abbrvHoraire: ['AM','DIN','SOU','HS'],
      horaires: ['MP1','MP2','MP3','MP4'],
      dialog: false,
    };
  },
  filters: {
    round(value) {
      return Math.round(value);
    },
    toDate(value, separator) {
      if (!value) return '';
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${separator}${month}${separator}${day}`;
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    hasChanged(key){
      return this.show_diff && this.registre_changement?.diff!=null && (hasProperty(this.registre_changement?.diff?.added,key) || hasProperty(this.registre_changement?.diff?.updated,key));
    },
    getClassMedicament(key,nomMedicament){
      if(this.show_diff && this.registre_changement?.diff!=null){
        const pattern = /^(.*?)\s*\((.*?)\)$/;
        let diffNomMedicament = ""
        if(hasProperty(this.registre_changement?.diff?.added,key)){
          return this.type == "droite" ?  "highlight-red" : "highlight-orange";
        }
        else if(hasProperty(this.registre_changement?.diff?.updated,key)){
          diffNomMedicament = getProperty(this.registre_changement?.diff?.updated,key);
        }else{
          return "";
        }
        let currentMatch = nomMedicament.match(pattern);
        let diffMatch = diffNomMedicament.match(pattern);
        if(diffMatch){
          if(currentMatch[1]!=diffMatch[1]){
            return this.type == "droite" ?  "highlight-red" : "highlight-orange";
          }
          if(currentMatch[0]!=diffMatch[0]){
            return "highlight-orange";
          }
        }else if(diffNomMedicament!=currentMatch[1]){
          return "highlight-orange";
        }
      }
      return "";
      
    },
    getClass(has_modif){
      if(has_modif){
        return this.type == "droite" ?  "highlight-red" : "highlight-orange";
      }
      return "";
    },
    signerRegistre(){
      this.dialog = false;
      this.$emit("signer_registre");
    },
    formatDecimal(val){
      return Math.round(val) == val ? val : val.toFixed(2)
    },
    formatPrescripteur(nom){
      let strs_nom = nom.split(' ')
      return (this.capitalize(strs_nom[0].substr(0,3))+ (strs_nom.length>1 ? this.capitalize(strs_nom[strs_nom.length-1].substr(0,2)) : ''))
    },
    capitalize(word){
      return word.charAt(0).toUpperCase()+ word.slice(1).toLowerCase()
    },
    formatPosologieHebdo(posologie_json){
      const daysOfWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
      var horairePrise = {MP1:'',MP2:'',MP3:'',MP4:''}
      daysOfWeek.forEach(day => {
        this.horaires.forEach((horaire) => {
            if(posologie_json.sequence[day].sequence[horaire]!=""){
              horairePrise[horaire] = 'X'
            }
        });
      });
      return horairePrise
    },
  },
};
</script>

<style scoped>
.v-card{
  padding: 15px;
}
.qte-duree-column {
  width: 35px;
  text-align: right;
  padding: 0px 2px;
}
.medicament-column {
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding: 0px 2px;
}
.teneur-column {
  width: 100px;
  text-align: left;
  padding: 0px 2px;
}
.prescripteur-column {
  width: 43px;
}
.horaire-column{
  width: 30px;
}
.bold-line{
  border-bottom: 1px solid black;
}
.date-line{
  text-align: center;
}
</style>
